import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Button.module.scss';

const Button = ({ mobile, children }) => {
    return mobile ? (
        <button className={`${styles.wrapper}`}>
            <span className={styles.mobile}>{mobile}</span>
            <span className={styles.desktop}>
                {mobile} {children}
            </span>
        </button>
    ) : (
        <button className={`${styles.wrapper}`}>{children}</button>
    );
};

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
};

export default Button;

import React from 'react';
import { Parallax } from 'react-parallax';
import * as styles from './Footer.module.scss';

import FooterImg from '../../../images/footer.jpg';

const Footer = ({ active }) => {
    return (
        <footer className={`${styles.wrapper} ${active && styles.active}`}>
            <div className={styles.img}>
                <Parallax
                    blur={{ min: -15, max: 15 }}
                    bgImage={FooterImg}
                    bgImageAlt="Uczciwość, Odpowiedzialność, Transparentność"
                    strength={-100}
                >
                    <div className={styles.content}>
                        <h2>
                            Dziękujemy za
                            <br />
                            Wasze zaufanie!
                        </h2>
                    </div>
                </Parallax>
            </div>
            <div className={styles.copy}>
                Sfinansowano ze środków KWW Po Stronie Mieszkańców Agnieszki
                Kobylarz
            </div>
        </footer>
    );
};

export default Footer;

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import * as styles from './Header.module.scss';
import { Link } from 'gatsby';

import Button from '../../atoms/Button/Button';
import Logo from '../../atoms/Logo/Logo';

import ChevIcon from '../../../images/chev-left.svg';
import FacebookIcon from '../../../images/facebook.svg';
import TikTokIcon from '../../../images/tiktok.svg';
import YoutubeIcon from '../../../images/youtube.svg';
import FileSvg from '../../../images/file-text.svg';

const Header = ({ active }) => {
    const socials = [
        {
            id: '01',
            link: 'https://www.facebook.com/agnieszka.kobylarz.bojanow',
            name: 'facebook',
            icon: FacebookIcon,
        },
        {
            id: '02',
            link: 'https://www.youtube.com/@PoStronieMieszkancow',
            name: 'youtube',
            icon: YoutubeIcon,
        },
        {
            id: '03',
            link: 'https://www.tiktok.com/@kobylarzagnieszka',
            name: 'tiktok',
            icon: TikTokIcon,
        },
    ];

    return (
        <div className={`${styles.wrapper} ${active && styles.active}`}>
            <div className={styles.content}>
                <div>
                    <Logo small />
                </div>
                <div>
                    <div className={styles.links}>
                        {socials.map((link) => (
                            <a
                                href={link.link}
                                rel="noopener noreferrer"
                                key={link.name}
                            >
                                <SVG src={link.icon} />
                            </a>
                        ))}
                    </div>
                    <div className={styles.noMobile}>
                        <Link to="/program">
                            <Button mobile={<SVG src={FileSvg} />}>
                                Program
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bilans-doliny-zdrowia-js": () => import("./../../../src/pages/bilans-doliny-zdrowia.js" /* webpackChunkName: "component---src-pages-bilans-doliny-zdrowia-js" */),
  "component---src-pages-bojanow-js": () => import("./../../../src/pages/bojanow.js" /* webpackChunkName: "component---src-pages-bojanow-js" */),
  "component---src-pages-bojanow-za-rzeka-js": () => import("./../../../src/pages/bojanow-za-rzeka.js" /* webpackChunkName: "component---src-pages-bojanow-za-rzeka-js" */),
  "component---src-pages-burdze-js": () => import("./../../../src/pages/burdze.js" /* webpackChunkName: "component---src-pages-burdze-js" */),
  "component---src-pages-gwozdziec-js": () => import("./../../../src/pages/gwozdziec.js" /* webpackChunkName: "component---src-pages-gwozdziec-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-korabina-i-cisow-las-js": () => import("./../../../src/pages/korabina-i-cisow-las.js" /* webpackChunkName: "component---src-pages-korabina-i-cisow-las-js" */),
  "component---src-pages-laski-js": () => import("./../../../src/pages/laski.js" /* webpackChunkName: "component---src-pages-laski-js" */),
  "component---src-pages-maziarnia-i-kolodzieje-js": () => import("./../../../src/pages/maziarnia-i-kolodzieje.js" /* webpackChunkName: "component---src-pages-maziarnia-i-kolodzieje-js" */),
  "component---src-pages-program-js": () => import("./../../../src/pages/program.js" /* webpackChunkName: "component---src-pages-program-js" */),
  "component---src-pages-przyszow-js": () => import("./../../../src/pages/przyszow.js" /* webpackChunkName: "component---src-pages-przyszow-js" */),
  "component---src-pages-ruda-js": () => import("./../../../src/pages/ruda.js" /* webpackChunkName: "component---src-pages-ruda-js" */),
  "component---src-pages-stany-js": () => import("./../../../src/pages/stany.js" /* webpackChunkName: "component---src-pages-stany-js" */)
}


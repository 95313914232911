import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import shareImage from '../../../images/icon.png';

import Header from '../../organisms/Header/Header';
import Footer from '../../organisms/Footer/Footer';

import '../../theme/sanitize.css';
import '../../theme/index.css';

const Layout = ({ location, children }) => {
    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true);
        }, 500);
    });

    return (
        <>
            <Helmet htmlAttributes={{ lang: 'pl' }}>
                <title>Po Stronie Mieszkańców | Gmina Bojanów</title>
                <meta
                    name="description"
                    content="Oficjalna strona internetowa KWW Po Stronie Mieszkańców. Komitetu kandydatki na Wójta Gminy Bojanów - Agnieszki Kobylarz. 7 kwietnia - rekordową w historii liczbą 2135 głosów - Agnieszka Kobylarz została wybrana na Wójta Gminy Bojanów! Dziękujemy za Wasze poparcie!"
                />
                <meta name="og:title" content="Po Stronie Mieszkańców" />
                <meta name="twitter:title" content="Po Stronie Mieszkańców" />
                <meta
                    name="og:description"
                    content="Oficjalna strona internetowa KWW Po Stronie Mieszkańców"
                />
                <meta
                    name="twitter:description"
                    content="Oficjalna strona internetowa KWW Po Stronie Mieszkańców"
                />
                <meta name="twitter:card" content="summary" />
                <meta
                    name="article:modified_time"
                    content="2023-11-01T09:00:00Z"
                />
                <meta name="og:locale" content="en_EN" />
                <meta name="og:type" content="article" />
                <meta name="og:site_name" content="postroniemieszkancow.pl" />
                <meta
                    property="og:url"
                    content="https://postroniemieszkancow.pl/"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Po Stronie Mieszkańców" />
                <meta
                    property="og:description"
                    content="Oficjalna strona internetowa KWW Po Stronie Mieszkańców"
                />
                <meta name="og:image" content={shareImage} />
                <meta name="og:image:width" content="1200" />
                <meta name="og:image:height" content="630" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    property="twitter:url"
                    content="https://postroniemieszkancow.pl/"
                />
                <meta name="twitter:title" content="Po Stronie Mieszkańców" />
                <meta
                    name="twitter:description"
                    content="Oficjalna strona internetowa KWW Po Stronie Mieszkańców"
                />
                <meta name="twitter:image" content={shareImage} />
                <link rel="canonical" href="https://postroniemieszkancow.pl" />
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/quj7rva.css"
                ></link>
            </Helmet>
            <Header active={pageLoaded} />
            {children}
            <Footer active={pageLoaded} />
            <script
                async
                data-id="101447186"
                src="//static.getclicky.com/js"
            ></script>
        </>
    );
};

export default Layout;

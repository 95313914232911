import React from 'react';
import * as styles from './Logo.module.scss';

import LogoOnLight from '../../../assets/logo.png';
import { Link } from 'gatsby';

const Logo = ({ small }) => {
    return (
        <Link to="/">
            <div className={`${styles.wrapper} ${small && styles.small}`}>
                <img src={LogoOnLight} />
            </div>
        </Link>
    );
};

export default Logo;
